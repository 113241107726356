body {
  font-family: sans-serif;
  padding: 25px;
  background-color: #e8b9e4;
  text-align: enter;
  background-image: url("https://www.supercryptonews.com/wp-content/uploads/2020/02/S__3743751-1.jpg");
  
}

.form-select{
  width: 50px;
}


.dropdown-button {
  background-color: #4CAF50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown-button:hover, .dropdown-button:focus {
  background-color: #3e8e41;
}
.displyflex{
  max-width: 20px;
  min-width: 1000px;
  display: flex;
  gap: 100px;
  
}

.circle-img{
  border-radius: 50%;
  width: 100px;
  margin-right: 6px;
}

.heading {
  text-align: center;
  font-size: 2em;
  color: #76696a;
}
.employeeList2{
     width: 100vw;
     display: flex;
  flex-wrap: wrap;
 
}

.card {
  border-radius: 25px;
  position: relative;
  padding: 25px 15px;
  background-color: #3bbb4f;
  margin: 50px ;
  height: 270px;
  box-shadow: 0 2px 5px #9a9292;
  text-align: left;
  width: 400px;
}
/* #root{
display: flex;
flex-wrap: wrap;

} */

.top {
  border-radius: 25px 25px 0 0;  
  height: 100px;
  width: 100%;
  background-color: #e35075;
  position: absolute;
  top: 0;
  left: 0;
 display: flex;
}

.name {
  font-size: 25px;
  color: #2d3436;
  display: flex;
  flex: 1;
  margin: 10% 20px 0;
}



.bottom {
  margin-top: 90px;
}

.info {
  margin: 20px 0;
  color: #1a7e76;
}



.lb2{
  background-color: transparent;
  border: 2px solid;
  width: 100px;
  margin-left: 55px;
  
} 
.imgh{
  width: 350px;
  margin-left: 900px;
}





/* Candidatelist css */




body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.employeeList{
  margin-left: 50px;
}

.employeeData{
 background-color: rgb(167, 215, 199);
 border:  1px solid black;
 width: fit-content;
 display: flex;
 height: 50px;
}

.employeeData div{
  border:  1px solid;
  width: 150px;
  text-align: center;
 }

 .employeeData button{
 margin-left: 12px;
 margin-right: 10px;
 border:  1px solid;
 width: 80px;

 }

 

 .he{
  font-size: 50px;
  border: 5px solid black;
  padding: 10px;
  border-radius: 50px 20px;
 }

.bg{
  background-color: rgb(173, 124, 234);
}





 .result{
  font-size: 30px;
  border: 4px dotted red;
  width: fit-content;
  background-color: #c8d5e7;

 }


.card2 {
  border-radius: 25px;
  position: relative;
  padding: 25px 15px;
  background-color: #3bbb4f;
  margin: 30px ;
  height: 500px;
  box-shadow: 0 2px 5px #9a9292;
  text-align: left;
  width: 1000px;
}

.circle-img2{
  border-radius: 50%;
  width: 120px;
  margin-right: 3px;
  margin-left: 700px;
 margin-top: 1px;
position: relative;
justify-content: flex-end;
display: flex;
}


.info1{
  margin: 50px 0;
  color: #102321;
  margin-bottom: 70px;
  position: relative;
  display: flex;
flex-wrap: wrap;
margin-right: 100px;
font-size: 20px;
}

.name1 {
  font-size: 25px;
  color: #2d3436;
  display: flex;
  /* flex: 1; */
  margin: 2% 20px 0;
}



/* CSS */
.btnv {
  align-items: center;
  background-color: #fee6e3;
  border: 2px solid #111;
  border-radius: 8px;
  box-sizing: border-box;
  color: #111;
  cursor: pointer;
  display: flex;
  font-family: Inter,sans-serif;
  font-size: 16px;
  height: 48px;
  justify-content: center;
  line-height: 24px;
  max-width: 100%;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-left: 700px;
  margin-top: 10px;
}

.btnv:after {
  background-color: #111;
  border-radius: 8px;
  content: "";
  display: block;
  height: 48px;
  left: 0;
  width: 100%;
  position: absolute;
  top: -2px;
  transform: translate(8px, 8px);
  transition: transform .2s ease-out;
  z-index: -1;
}

.btnv:hover:after {
  transform: translate(0, 0);
}

.btnv:active {
  background-color: #ffdeda;
  outline: 0;
}

.btnv:hover {
  outline: 0;
}

@media (min-width: 768px) {
  .btnv {
    padding: 0 40px;
  }
}

.login{
  margin-left: 350px;
  box-sizing: border-box;
  font-size: 16px;
  font-size: max(16px, 1em);
  font-family: inherit;
  padding: 0.25em 0.5em;
  background-color: #fff;
  border: 2px solid var(--input-border);
  border-radius: 4px;

}
.slogan{
 margin-left: 300px;
 border: 1px solid;
 background-color: #ADCFFF;
}

.main{

  font-size: 55px;
  border: 5px solid black;
  padding: 10px;
  border-radius: 50px 20px;
}


/* CSS */
.button-73 {
  appearance: none;
  background-color: #FFFFFF;
  border-radius: 40em;
  border-style: none;
  box-shadow: #ADCFFF 0 -12px 6px inset;
  box-sizing: border-box;
  color: #000000;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: -.24px;
  margin: 0;
  outline: none;
  padding: 1rem 1.3rem;
  quotes: auto;
  text-align: center;
  text-decoration: none;
  transition: all .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
 
}

.button-73:hover {
  background-color: #FFC229;
  box-shadow: #FF6314 0 -6px 8px inset;
  transform: scale(1.125);
}

.button-73:active {
  transform: scale(1.025);
}

@media (min-width: 768px) {
  .button-73 {
    font-size: 1.5rem;
    padding: .75rem 2rem;
  }
}















.title {
  text-align: center;
  width: 30vw;
  background-color: rgb(190, 164, 214);
  padding: 2vw 1vw;
  border-radius: 10px 10px 0 0;
  font-size: 2rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 30vw;
  padding: 2vw 1vw;
  background-color: rgb(250, 194, 194);
  border-radius: 0 0 10px 10px;
}

input {
  border: 1px solid rgb(172, 171, 171);
  border-radius: 10px;
  padding: 1vw 1vw;
  outline: none;
  margin: 5px;
}








